import React from "react"
import Button from "../../components/Button/Button"
import Columns from "../../components/Columns/Columns"
import Layout from "../../components/Layout/Layout"
import MainSection from "../../components/MainSection/MainSection"
import Section from "../../components/Section/Section"
import Seo from "../../components/Seo/Seo"
import "../../styles/transactions.scss"
import ResistanceCode from "../../svg/HuntUnexpected.svg"
import ResistanceTheme from "../../svg/Augment.svg"
import detect from "../../svg/MagnifyingGlass.svg"
import resolve from "../../svg/Resolve.svg"
import enhance from "../../svg/Enhance.svg"
import improve from "../../svg/Improve.svg"
import protect from "../../svg/Augment.svg"
import QuoteSection from "../../components/QuoteSection/QuoteSection"
import no_burnout from "../../images/ilustrations/No_Burnout.png"
import transaction__theme from "../../images/ilustrations/Transactions-Main.svg"
import oversight__img from "../../images/ilustrations/Transactions-Problem.svg"
import discovery from "../../images/ilustrations/Transactions-Solution.svg"
//@ts-ignore
import Fade from "react-reveal/Fade"
import NavigationCard from "../../components/NavigationCard/NavigationCard"


const Transactions = () => {
  return (
    <Layout>
      <Seo
        title="Find New Threats & Reduce Alarms in AML & Scoring Systems"
        description="Analyzes transactions in real time to supercharge AML, prevent fraud in BNPL, and reveal vulnerabilities in scoring systems."
      />

      <div className="Transactions__main">
        <MainSection
        className="Career__main"
        showReferences
        divided
        imageLink={transaction__theme}
      >
        <div className="MainSection__caption">Transactions Forensics</div>
        <h1 className="MainSection__title">Find <span className="highlighted">New Threats</span> Reduce <span className="highlighted">False Alarms</span></h1>
        <div className="MainSection__text">Simplify fraud detection, AML or scoring system. We don’t score. We hunt. Resistant AI wraps your existing scoring system in an adaptive protective layer.</div>
        <div className="MainSection__button">
        <Button
          {...{
            variant: "blue",
            link: "/contact",
            text: "Get started",
            size: "large",
          }}
        />
        </div>
      </MainSection>
      </div>

      <Section className="Transactions__oversight" id="financial-automation-oversight">
        <div>

          <div className="w-wrap">
            <div className="w1-2">
              <h2 className="mt-8 ">The <span className="highlighted">Problem</span></h2>
              <p>
                Systems tasked with detecting financial crime, scoring risk, and monitoring the
                compliance of transactions in different jurisdictions make critical and complex
                decisions every second. Transaction volumes and the desire for almost instantaneous
                response in the on-demand economy make human oversight impractical. Automation has
                replaced much of what was previously done manually, further accelerating innovation.
              </p>
              <p>
                But automation is vulnerable. Smart criminals actively probe systems
                to identify weaknesses in their defenses before exploiting them at
                scale, causing tremendous damage in a short time.
              </p>
            </div>
            <div className="w1-2">
              <img alt="Financial crime" src={oversight__img} />
            </div>
          </div>

        </div>
      </Section>

      <Section gray>

        <div className="w-wrap wHeadRow">
          <div className="w1-3"><h2>The <span className="highlighted">Solution</span></h2></div>
          <div className="w2-3">
            <p>
              We extend our clients’ processes and monitor each interaction for behavioral anomalies. By hunting for criminals while they develop their attack strategy, we protect the underlying systems from new threats before damage can occur. When human intervention is required, we narrow the focus by prioritizing and contextualizing alerts, simplifying analyses, and improving the working lives of financial crime protection teams.
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="w-wrap Transactions__automation">
           <div className="w1-3">
              <h3><img alt="Detect" src={detect} />Detect</h3>
              <p className="">
                discover and block novel and previously unknown threats and
                attacks
              </p>
            </div>
            <div className="w1-3">
              <h3><img alt="Resolve" src={resolve} />Resolve</h3>
              <p>
                reduce false alarms and rejections, with full transparency and
                explanation of the underlying rationale
              </p>
            </div>
            <div className="w1-3">
              <h3><img alt="Protect" src={protect} />Protect</h3>
              <p className="">
                prevent fraud occurring automatically before approval
              </p>
            </div>
          </div>

          <div className="w-wrap Transactions__automation">
            <div className="w1-3">
              <h3><img alt="Improve" src={improve} />Improve</h3>
              <p>
                the working lives of risk analysts by prioritising alerts in
                line with company thresholds for specific regulatory
                jurisdictions and business domains
              </p>
            </div>
          <div className="w1-3">
            <h3><img alt="Enhance" src={enhance} />Enhance</h3>
            <p>
              the decision-making process overall by eliminating fraud from the
              data
            </p>
          </div>
        </div>
      </Section>

      <Section sectionClass="Transactions__discovery patternedGray">
        <div className="w-wrap">

          <div className="w1-2">
            <img alt="Our approach" src={discovery} />
          </div>

          <div className="w1-2">
            <h2><span className="highlighted">Our</span> Approach</h2>
            <p>We actively discover new and unknown threats by constantly connecting the dots in the data and uncovering unlikely dependencies and correlations.</p>
            <p>We hunt by patiently connecting the dots and picking up the transaction groups that stand out - not as individuals, but as clusters of transactions.</p>
          </div>

        </div>

      </Section>
      <Section className="Transactions__results">

        <div className="w-wrap">
          <h2>The <span className="highlighted">Result:</span>
          <br/>Active Discovery of <span className="highlighted">New Threats</span></h2>
        </div>

        <Fade up distance="250px">
          <div className="w-wrap">
            <div className="w1-2 Transactions__column">
            <img alt="Intelligent hunting" src={ResistanceTheme} className="Transactions__theme-svg" />
              <div>
                <h3>Intelligent hunting</h3>
                <p>
                  Discover new attacks, manipulative inputs and other threats to
                  create a frictionless user experience for your customers.
                </p>
              </div>
            </div>
            <div className="w1-2 Transactions__column">
            <img alt="AI Systems protection" src={ResistanceCode} className="Transactions__theme-svg" />
              <div>
                <h3>AI system protection</h3>
                <p>
                  Apply adaptive security controls to protect AI systems and
                  your business from fraudsters while minimizing customer
                  friction.
                </p>
              </div>
            </div>
          </div>

        </Fade>
      </Section>

      <div className="Separator"></div>

      <QuoteSection
        className="Homepage__quote"
        quotes={[
          {
            restOfQuote:
              "Resistant AI dramatically reduces false positives and detects advanced fraud and manipulation at the same time. Under the protection of Resistant AI, our Nikita engine can fully focus on credit risk assessment excellence.",
            quoteBy: "Michal Krocil",
            companyName: "Twisto",
            position: "Chief Risk Officer",
            link: "https://www.twisto.cz/",
          },
          {
            restOfQuote:
              "We use Resistant AI’s technology in our KYC process. We greatly value the insights gained to help us validate the authenticity of documents submitted by customers.",
            quoteBy: "Keren Levy",
            companyName: "Payoneer",
            position: "COO",
            link: "https://www.payoneer.com",
          },
          {
            restOfQuote:
              "Resistant AI gives us reliable, real-time insights into our customer data. With high degrees of precision and accuracy.",
            quoteBy: "Dan Hagerty",
            companyName: "Habito",
            position: "CEO - Founder",
            link: "https://www.habito.com/",
          },
          {
            restOfQuote:
              "Resistant AI prevents the manipulation of invoices submitted to our marketplace. It allows our investors to trade in security and saves my team a huge amount of manual work.",
            quoteBy: "Alexandra Belkova",
            companyName: "Roger",
            position: "Head of Operations",
            link: "https://www.roger.cz",
          },
        ]}
      ></QuoteSection>

    </Layout>
  )
}

export default Transactions
